import React, { useState, useEffect} from 'react'
import axios from 'axios';
import {Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import ReportPage from './ReportPage';
import * as constant from './Constants/GlobalConstant';
import { is } from 'express/lib/request';
import { EventAvailableTwoTone } from '@material-ui/icons';
import Logout from './Logout';
import SessionTimeout from './SessionTime';

const HomePage = (props) => {
  const [data, setData] = useState();
  // const [isLogin,setLogin] = useState(False)
  const location = useLocation();
  const history = useHistory();
//   const logout = () =>{
//     Logout();
//     history.push('/');
// }
  let sourceUrl = "#";
  location.state?.data[0]  ? sourceUrl = location.state.data[0] : history.push('/');


  console.log("datat", location.state);

//   var add_minutes =  function (dt, minutes) {
//     return new Date(dt.getTime() + minutes*60000);
// }

  useEffect(() => {
    console.log('Success response ==>');
    window.addEventListener("unload", Logout);

    axios.post(`${constant.EMBED_URL}/getEmbedTokenById`,sourceUrl)
      .then(res => {
        if (res.status === 200) {
          console.log('Success response to token service wwww==>', res);
          setData(res.data);   
          console.log(res.data);
          // var date = new Date(res.data.expiry);
          // console.log("expiry",date.toLocaleString());
          // var previous_date = new Date();
          // res.data.expiry = add_minutes(previous_date,3)
          // date = res.data.expiry;
          // console.log("new expiry",date.toLocaleString());
          sessionStorage.setItem("current_user", JSON.stringify
          (res.data));
              
        }
      }).catch((err) => {
        console.log('Error => Token service==>', err);
      });
  }, []);

  
  return (
    <>
   
    <SessionTimeout></SessionTimeout>
    <div>
      {data && data.embedUrl&&
            <ReportPage
            reportAllData={data}
          />}
    </div>
    </>
  );
}

export default HomePage;