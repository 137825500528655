
import axios from 'axios';
import * as constant from './Constants/GlobalConstant'


export default function Logout() 
   {
      
       const data = JSON.parse(sessionStorage.getItem('current_user'));
       console.log("logout",data)
       const expiry = data.expiry;
       var expiry_sec = new Date(expiry);
       var userLoginInfoId = sessionStorage.getItem('LoginInfo')
       let userLogout = {
           "LoginInfoId": userLoginInfoId,
           "LoggedoutTime": new Date()

       }
       console.log("checking expiry");
       console.log(userLogout)
       axios.post(`${constant.EMBED_URL}/submitLogoutInfo`, userLogout)
       .then(res => {
           if (res.status === 200) {
               console.log("res",res.data)
               sessionStorage.clear()
           }
       }).catch((err) => {
           console.log('Error => Logout Error==>', err);
           
       });


   }
