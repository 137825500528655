import React from "react";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from './HomePage'
import Login from './Login.js'
import './App.css';



export default function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/home" exact component={HomePage} />
            </Switch>
        </Router>
    );
}