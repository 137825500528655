import React, { useState, useEffect } from 'react'
// import * as Dimens from '../../res/values/Dimens'
import { makeStyles, Card, Grid, Button } from '@material-ui/core';
import { Report } from "powerbi-report-component";
import axios from 'axios';
import * as constant from './Constants/GlobalConstant'
import { useHistory } from 'react-router-dom';
import Logout from './Logout';
import SessionTimeout from './SessionTime';

export default function ReportPage(props) {
    const history = useHistory();
    const data = props.reportAllData;
    var reportData;

   
  

    const onClickChangeReport = (report) => {
        reportData = report;

    }

    const logout = () =>{
        Logout();
        history.push('/');
    }

    const handleReportRender = (report) => {
        reportData = report;
        console.log('reportData', reportData);
    }

  

    const onGenerateReport = () => {
        // let element=document.querySelectorAll("div > div:nth-child(2) > div")[4]
        // // console.log("ReportPage=> elements",element);
        // if(element && element.innerText=="DOWNLOAD"){
        //     reportData && reportData.print();
        // }        
        // else{

        // }
                  window.location.reload(); 
    }




    return (
       <>
      
       <div id="pbiReport_section" >
                
                {data && (
                            
                    <Grid container>
                         
    
                          <Grid item xs={12} md={8} lg={10}>
                          
                            <Card id="pbiReport_1" >
                                <Report
                                    embedType="visual"
                                    tokenType="Embed"
                                    accessToken={data['accessToken']}
                                    embedUrl={data["embedUrl"][0].embedUrl}
                                    embedId={data["embedUrl"][0].reportId}
                                    permissions="All"
    
                                    extraSettings={{
                                        filterPaneEnabled: false,
                                        navContentPaneEnabled: false,
                                        filters: true
                                    }}
                                    // onButtonClicked={onGenerateReport}
                                    onLoad={onClickChangeReport}
                                    onRender={handleReportRender}
    
                                />
                                
                            </Card>
                            
                            </Grid>
                            
                            {/* <Grid item xs={2} md={2} lg={2}>
                                <Button variant='contained' color='primary'  style={{background:'#00324A'}}onClick={onGenerateReport}>Refresh</Button>
    
                            </Grid> */}
                            <Grid item xs={2} md={2} lg={2}> <Button onClick={logout}  variant='contained'   
                            style={{background:'#07161C', float:'right', marginTop: 15, marginRight: 34, fontFamily:'Segoe UI semibold', fontSize:10,color:'#FFFFFF' }}
                             role="button">LogOut</Button></Grid>
    
                    </Grid>
    
                )}
    
            </div>
       </>
       

    )
}



