import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import * as constant from './Constants/GlobalConstant';
import Logout from './Logout';
import { useHistory } from 'react-router-dom';

const SessionTimeout = () => {
    const history = useHistory();
    const events = ['click', 'scroll','mouseover'];
    // const [second, setSecond] = useState(0);

    const checkExpiry = () => {
        const data = JSON.parse(sessionStorage.getItem('current_user'));
        if(data){
            const expiry = data.expiry;
            var expiry_sec = new Date(expiry);
            // console.log("expiry",expiry_sec.toLocaleString());
            // console.log("checking expiry");
            // console.log(new Date());
            var current_date = new Date();
            // Math.round((dif/1000)/60)
            var time_left = Math.round(((expiry_sec - current_date)/1000)/60);
            // console.log(time_left);
            if(time_left < 1)
            {
                Logout();
                history.push('/');
            }
        }
    }

    useEffect(() => {
        events.forEach((event) => {
          window.addEventListener(event, checkExpiry);
        })
     },[]);

     return(
         null
     )
}
export default SessionTimeout;